import { InjectionToken } from '@angular/core';
import { SchoolUserNew } from '@memberspot/shared/model/school-user';
import { Observable } from 'rxjs';

export interface ClientDashboardUserService {
  select(): Observable<SchoolUserNew | undefined>;
}

export const CLIENT_DASHBOARD_USER_SERVICE_TOKEN: InjectionToken<ClientDashboardUserService> =
  new InjectionToken<ClientDashboardUserService>(
    'CLIENT_DASHBOARD_USER_SERVICE_TOKEN',
  );
